<template>
  <div>
    <div class='py-2 lg:pb-8 relative'>
      <content-background-cover background-color='#001020' class='z-0' :top-offset='0' />
      <div class='pb-4 flex flex-row justify-between items-center'>
        <button @click='goBack'
          class='pl-1 pr-2 hover:pl-0 rounded text-white border border-transparent hover:border-gray-200 flex flex-row justify-center items-center text-normal'>
          <arrow-narrow-left-icon class='h-4' />
          Back
        </button>
      </div>
      <abstract-content />
    </div>
    <div class='mt-8 pb-64 px-2 lg:px-0'>
      <div class='flex flex-col lg:flex-row lg:gap-x-4'>
        <div style='width:100%;'>
          <h1 class='poster-title'>
            {{ selectedAbstract.title || '' }}
          </h1>
          <h3 class='poster-tag'>
            {{ tagsString }}
          </h3>
          <h3>
            {{ authorsNames }}
          </h3>
          <h3 class='mb-8'>
            {{ authorsAffiliations }}
          </h3>
          <div v-if='selectedAbstract.description'>
            <h2 class='text-lg font-semibold mb-1'>Description</h2>
            <h3 class='whitespace-pre-line'>
              {{ selectedAbstract.description }}
            </h3>
          </div>
        </div>
      </div>
      <div class='mt-8'>
        <star-rating v-model='myScore' :star-size='24' :animate='true' :padding='1' :glow='1' @rating-selected='setScore' />
      </div>
      <comments
        :comments='mainComments' 
        class='mt-8' />
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import Comments               from '@/components/comments/Comments.vue'
import AbstractContent        from '@/components/contents/AbstractContent.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'

export default {
  name: 'Abstract',
  components: {
    StarRating,
    ArrowNarrowLeftIcon,
    AbstractContent,
    Comments,
    ContentBackgroundCover,
  },
  data () {
    return {
      myScore: 0,
    }
  },
  watch: {
    'selectedAbstract.myScore': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setMyRating(newVal)
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('comments', [
      'mainComments',
    ]),
    ...mapState('abstracts', [
      'selectedAbstract',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    tagsString () {
      if (this.selectedAbstract && this.selectedAbstract.conferenceTags) {
        let tagList =  this.selectedAbstract.conferenceTags.map(tag => {
          return tag.list
        }).flat()
        return tagList.map(tag => {
          return `#${tag}`
        }).join(' ')
      } else {
        return ''
      }
    },
    authorsNames () {
      return (this.selectedAbstract && this.selectedAbstract.authors) ? this.selectedAbstract.authors.map(author => `${author.firstName} ${author.lastName}`).join(', ') : ''
    },
    authorsAffiliations () {
      return (this.selectedAbstract && this.selectedAbstract.authors) ? this.selectedAbstract.authors.map(author => `${author.affiliation}`).join(', ') : ''
    },
  },
  methods: {
    ...mapActions('comments', [
      'updateCommentInstance',
      'getComments',
    ]),
    ...mapActions('abstracts', [
      'getSingleAbstract',
      'updateAbstractScore',
      'resetSelectedAbstract',
    ]),
    goBack () {
      this.$router.go(-1)
    },
    setMyRating (newVal) {
      this.myScore = newVal
    },
    setScore (score) {
      this.updateAbstractScore(score)
    },
  },
  mounted () {
    this.getSingleAbstract(this.$route.query.abstract_id).then(() => {
      this.updateCommentInstance({instanceType: 'AbstractSubmission', instanceId: this.$route.query.abstract_id})
      this.getComments({instanceType: 'AbstractSubmission', instanceId: this.$route.query.abstract_id})
    })
  },
  beforeDestroy () {
    this.resetSelectedAbstract()
  },
}
</script>

<style type='scss' scoped>
.poster-title {
  @apply text-gray-700 text-2xl font-semibold mb-4;
}

.poster-tag {
  color: #529dad;
  @apply mb-4 font-medium;
}
</style>
